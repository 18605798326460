<template>
  <q-form ref="editForm">
    <c-card title="위험성평가 개요" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-update-btn 
            name="updateBtn"
            :data="param.planUpdateBtnData"
            :btnEditable="btnEditable"
            :flagCondition="flagCondition"
            @back="back"
          />
          <c-btn v-show="editable&&!completeDisabled&&isRemove" label="삭제" icon="remove" @btnClicked="removePlan" />
          <c-btn 
            v-show="editable&&isOld&&!completeDisabled" 
            label="지난 위험성평가 재검토"
            icon="autorenew" 
            @btnClicked="reCheckRisk" />
          <!-- <c-btn v-show="editable&&!param.riskAssessmentPlanId" label="타위험성평가 복사" icon="save_alt" @btnClicked="addDiffPlan"/> -->
          <c-btn 
            v-show="editable&&!completeDisabled"
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="assessPlan"
            mappingType="PUT"
            label="계획완료" 
            icon="check"
            @beforeAction="completePlan"
            @btnCallback="completePlanCallback" />
          <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="assessPlan"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="saveAssessPlan"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            :changeItem="changeItem"
            type="edit" 
            name="plantCd" 
            v-model="assessPlan.plantCd"
            @datachange="plantDatachange" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            label="평가명"
            name="assessmentName"
            v-model="assessPlan.assessmentName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :range="true"
            label="평가기간"
            name="period"
            v-model="assessPlan.assessmentPeriod"
          />
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            :required="true" 
            :disabled="disabled"
            :editable="editable"
            :plantCd="assessPlan.plantCd"
            :changeItem="changeItem"
            label="평가기관"
            name="assessmentManageVendorCd"
            v-model="assessPlan.assessmentManageVendorCd" />
        </div> -->
        <!-- <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            type="year"
            label="평가년도"
            name="assessmentYear"
            v-model="assessPlan.assessmentYear"
          />
        </div> -->
        <!-- <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="matrixItems"
            type="edit"
            itemText="matrixName"
            itemValue="ramMatrixId"
            name="ramMatrixId"
            label="Matrix"
            v-model="assessPlan.ramMatrixId"
          ></c-select>
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-project-location
            :editable="editable"
            :disabled="disabled"
            :plantCd="assessPlan.plantCd"
            :changeItem="changeItem"
            label="작업장소"
            name="sopMapId"
            v-model="assessPlan.sopMapId"
          >
            <template slot="search">
              <q-icon v-if="assessPlan.sopMapId&&editable" name="visibility" class="cursor-pointer" @click="srcAllClick">
                <q-tooltip>
                  {{'지도 및 전체 위치 보기'}}
                </q-tooltip>
              </q-icon>
            </template>
          </c-project-location>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            required
            :editable="editable"
            :disabled="disabled"
            :plantCd="assessPlan.plantCd"
            :changeItem="changeItem"
            :isVendorOrg="true"
            :vendorCd="vendorCd"
            type="user" 
            label="승인자" 
            name="approvalUserId" 
            v-model="assessPlan.approvalUserId" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            :disabled="disabled"
            :plantCd="assessPlan.plantCd"
            :changeItem="changeItem"
            :isVendorOrg="true"
            :vendorCd="vendorCd"
            type="user" 
            label="검토자" 
            name="reviewUserId" 
            v-model="assessPlan.reviewUserId" />
        </div>
        <div class="col-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="상세내용"
            name="remark"
            v-model="assessPlan.remark">
          </c-textarea>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="업체/작성자/작성일"
            name="request"
            v-model="request">
          </c-text>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="평가대상 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="assessPlan.targetList"
          :merge="grid.merge"
          :columnSetting="false"
          :expandAll="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :usePaging="false"
          :hideBottom="true"
          :filtering="false"
          :editable="editable&&!disabled"
          selection="multiple"
          rowKey="riskAssessmentTargetId"
          @innerBtnClicked="innerBtnClicked"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-show="editable&&!disabled" label="추가" icon="add" @btnClicked="addTarget" />
              <c-btn v-show="editable&&!disabled" label="제외" icon="remove" @btnClicked="removeTarget" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='src'">
              <q-btn 
                round unelevated 
                size="6px"
                color="amber" 
                icon="search"
                @click="srcClick(props.row, props.pageIndex)" />
            </template>
            <template v-else-if="col.name==='sopName'">
              <c-text-column
                :editable="editable&&!disabled"
                :disabled="disabled"
                :col="col"
                :props="props"
                @datachange="datachange(props.row, col)"
              />
              <div v-if="editable&&!disabled&&Boolean(props.row.sopName)">
                <q-btn-group outline class="ColumInnerBtnGroup">
                  <q-btn
                    icon="add"
                    color="green"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props)">
                    <q-tooltip>
                      평가실행업체 추가
                    </q-tooltip>
                  </q-btn>
                </q-btn-group>
              </div>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="관련자료" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-12">
              <c-upload 
                :attachInfo="attachInfo"
                label="첨부파일"
                maxheight="'max-height:500px;min-height:500px;width: 100%;'"
                :editable="editable&&!disabled">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'risk-assess-plan',
  props: {
    param: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        stepCd: '',
        planUpdateBtnData: {
          title: '계획',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'processName' },
          // { index: 1, colName: 'groupMdmSopId' },
        ],
        columns: [
          {
            required: true,
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:230px',
            sortable: false,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'blue', tooltip: '작업 추가' }
            ]
          },
          {
            required: true,
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:400px',
            type: 'custom',
            sortable: false,
          },
          {
            required: true,
            name: 'assessVendorId',
            field: 'assessVendorId',
            label: '평가실행(자체/업체)',
            align: 'center',
            style: 'width:400px',
            sortable: false,
            type: 'vendor',
          },
          {
            name: 'constructVendorCd',
            field: 'constructVendorCd',
            label: '공사업체',
            align: 'center',
            sortable: false,
            type: 'vendorMulti',
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '유해위험기계기구',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'text',
          },
          {
            name: 'workerCount',
            field: 'workerCount',
            label: '공사인원',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'number',
          },
          {
            name: 'src',
            field: 'src',
            label: '작업위치',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'custom',
          },
        ],
        data: [
          
        ],
        height: '300px'
      },
      assessPlan: {
        riskAssessmentPlanId: '',
        companyCd: '',
        plantCd: '',
        assessTypeCd: '',
        assessmentName: '',
        assessmentStartDate: '',
        assessmentEndDate: '',
        assessmentYear: '',
        stepCd: '',
        assessmentManageUserId: '',
        assessmentManageVendorCd: '',
        ramMatrixId: 'RM00000001',
        reviewDate: '',
        approvalUserId: '',
        approvalDate: '',
        sopMapId: '',
        chgUserId: '',
        remark: '',
        regUserId: '',
        regDt: '',
        reviewUserId: '',
        chgDt: '',
        targetList: [], // 대상공정 목록
        deleteTargetList: [], // 대상공정 목록
        vendorList: [],
        deleteVendorList: [],
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_ASSESS_PLAN',
        taskKey: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: true,
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isComplete: false,
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
      saveCallData: '',
      matrixItems: [],
      rowIndex: 0,
      row: null,
      deleteVendorList: [],
      vendorCd: '',
      vendorName: '',
      recheckSaveUrl: '',
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if ((this.editable && !this.disabled) || this.btnEditable) {
        numHeight = numHeight - 20
      }
      return numHeight + 'px';
    },
    isOld() {
      return Boolean(this.assessPlan.riskAssessmentPlanId)
    },
    request() {
      return this.assessPlan.regVendorName + '/' + this.assessPlan.regUserName + ' / ' + this.assessPlan.regDt
    },
    disabled() {
      return Boolean(this.assessPlan.stepCd) && this.assessPlan.stepCd !== 'RAS000001' && !this.param.planUpdateBtnData.flag
    },
    completeDisabled() {
      return this.assessPlan.stepCd !== 'RAS000001' || !this.assessPlan.riskAssessmentPlanId
    },
    flagCondition() {
      return this.assessPlan.stepCd === 'RAS000001'
    },
    btnEditable() {
      return this.editable && this.assessPlan.stepCd === 'RAS000005' && Boolean(this.assessPlan.riskAssessmentPlanId)
    },
    isRemove() {
      return this.$store.getters.user.userId === this.assessPlan.regUserId
    }
  },
  watch: {
    'param.planUpdateBtnData.planResearch'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.saas.assessPlan.get.url;
      this.saveUrl = transactionConfig.saas.assessPlan.insert.url
      this.completeUrl = transactionConfig.saas.assessPlan.complete.url
      this.deleteUrl = transactionConfig.saas.assessPlan.delete.url
      this.recheckSaveUrl = transactionConfig.saas.assessPlan.recheck.url
      // code setting
      // list setting
      this.getDetail();
    },
    plantDatachange() {
      if (!this.assessPlan.deleteTargetList) { 
        this.assessPlan.deleteTargetList = [];
      }
      this.$_.forEach(this.$_.cloneDeep(this.assessPlan.targetList), _item => {
        if (this.$_.findIndex(this.assessPlan.deleteTargetList, { riskAssessmentTargetId: _item.riskAssessmentTargetId }) === -1
          && _item.editFlag !== 'C') {
          this.assessPlan.deleteTargetList.push(_item);
        }
      })
        
      if (!this.assessPlan.deleteVendorList) { 
        this.assessPlan.deleteVendorList = [];
      }
      this.$_.forEach(this.$_.cloneDeep(this.assessPlan.vendorList), _item => {
        if (this.$_.findIndex(this.assessPlan.deleteVendorList, { riskAssessmentVendorId: _item.riskAssessmentVendorId }) === -1
          && _item.editFlag !== 'C') {
          this.assessPlan.deleteVendorList.push(_item);
        }
      })
      this.$refs['table'].$refs['compo-table'].clearSelection();
      this.$set(this.assessPlan, 'targetList', [])
      this.$set(this.assessPlan, 'vendorList', [])
    },
    getDetail() {
      if (this.param.riskAssessmentPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.param.riskAssessmentPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.assessPlan = _result.data

          this.assessPlan.deleteVendorList = this.$_.cloneDeep(this.assessPlan.vendorList)
          
          this.assessPlan.assessmentPeriod = this.assessPlan.assessmentStartDate && this.assessPlan.assessmentEndDate ?
            [this.assessPlan.assessmentStartDate, this.assessPlan.assessmentEndDate] : []
          this.$set(this.attachInfo, 'taskKey', this.assessPlan.riskAssessmentPlanId)

          if (this.$store.getters.user.innerFlag === 'Y') {
            this.editable = true;
          } else {
            if (this.$store.getters.user.deptCd === _result.data.regVendorCd) {
              this.editable = true;
            } else {
              this.editable = false;
            }
          }
        },);
      } else {
        this.assessPlan.regUserId = this.$store.getters.user.userId
        this.assessPlan.regUserName = this.$store.getters.user.userName
        this.assessPlan.regVendorName = this.$store.getters.user.deptName

        this.assessPlan.regDt = this.$comm.getToday();
      }
    },
    saveAssessPlan() {
      if (this.assessPlan.riskAssessmentPlanId) {
        this.saveUrl = transactionConfig.saas.assessPlan.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.saas.assessPlan.insert.url
        this.mappingType = 'POST';
        this.assessPlan.stepCd = 'RAS000001' // 평가계획
        this.assessPlan.companyCd = this.$store.getters.user.companyCd;
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.assessPlan.targetList)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // message: '저장하시겠습니까?(공종/작업별로 평가실행업체가 동일 할 경우 중복제거하고 저장됩니다.)',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.assessPlan.regUserId = this.$store.getters.user.userId
                this.assessPlan.chgUserId = this.$store.getters.user.userId

                this.assessPlan.assessmentStartDate = this.assessPlan.assessmentPeriod[0]
                this.assessPlan.assessmentEndDate = this.assessPlan.assessmentPeriod[1]

                this.$_.forEach(this.assessPlan.targetList, _item => {
                  if (this.$_.findIndex(this.assessPlan.vendorList, { assessVendorId: _item.assessVendorId}) === -1) {
                    this.assessPlan.vendorList.push({
                      assessVendorId: _item.assessVendorId,
                      assessVendorName: _item.assessVendorName,
                      processAssessStepCd: 'RS00000001',
                      riskAssessmentPlanId: this.assessPlan.riskAssessmentPlanId,
                      riskAssessmentVendorId: uid(),
                      editFlag : 'C',
                    })
                  }
                  this.assessPlan.deleteVendorList = this.$_.reject(this.assessPlan.deleteVendorList, { assessVendorId: _item.assessVendorId})
                })
                // let uniqTargetList = this.$_.uniqWith(
                //   this.assessPlan.targetList, (a, b) =>
                //     a.processCd === b.processCd &&
                //     a.assessVendorId === b.assessVendorId &&
                //     a.sopName === b.sopName
                // );
                // let targetList = this.$_.cloneDeep(this.assessPlan.targetList);
                // this.$_.forEach(uniqTargetList, _item => {
                //   targetList = this.$_.reject(targetList, { riskAssessmentTargetId: _item.riskAssessmentTargetId,})
                // });
                // if (!this.assessPlan.deleteTargetList) { 
                //   this.assessPlan.deleteTargetList = [];
                // }
                // if (targetList && targetList.length > 0) {
                //   this.assessPlan.deleteTargetList = this.$_.concat(this.assessPlan.deleteTargetList, targetList);
                // }
                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.riskAssessmentPlanId = result.data;
      this.$emit('emitStep', {
        name: 'keySetting',
        param: result.data
      })
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      window.getApp.$emit('APP_REQUEST_SUCCESS');

      this.param.planUpdateBtnData.research = uid();
      this.getDetail();
    },
    completePlan() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.assessPlan.targetList)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '완료하시겠습니까?',
              // message: '완료하시겠습니까?공종/작업별로 평가실행업체가 동일 할 경우 중복제거하고 저장됩니다.',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.assessPlan.regUserId = this.$store.getters.user.userId
                this.assessPlan.chgUserId = this.$store.getters.user.userId
                
                // this.assessPlan.targetList = this.assessPlan.targetList.filter((v, i) => 
                //   this.assessPlan.targetList.findIndex(x => x.processCd === v.processCd
                //   && x.assessVendorId === v.assessVendorId
                //   && x.sopName === v.sopName) === i)

                this.$_.forEach(this.assessPlan.targetList, _item => {
                  if (this.$_.findIndex(this.assessPlan.vendorList, { assessVendorId: _item.assessVendorId}) === -1) {
                    this.assessPlan.vendorList.push({
                      assessVendorId: _item.assessVendorId,
                      assessVendorName: _item.assessVendorName,
                      processAssessStepCd: 'RS00000001',
                      riskAssessmentPlanId: this.assessPlan.riskAssessmentPlanId,
                      riskAssessmentVendorId: uid(),
                      editFlag : 'C',
                    })
                  }
                  this.assessPlan.deleteVendorList = this.$_.reject(this.assessPlan.deleteVendorList, { assessVendorId: _item.assessVendorId})
                }) 
                
                // let uniqTargetList = this.$_.uniqWith(
                //   this.assessPlan.targetList, (a, b) =>
                //     a.processCd === b.processCd &&
                //     a.assessVendorId === b.assessVendorId &&
                //     a.sopName === b.sopName
                // );
                // let targetList = this.$_.cloneDeep(this.assessPlan.targetList);
                // this.$_.forEach(uniqTargetList, _item => {
                //   targetList = this.$_.reject(targetList, { riskAssessmentTargetId: _item.riskAssessmentTargetId,})
                // });
                // if (!this.assessPlan.deleteTargetList) { 
                //   this.assessPlan.deleteTargetList = [];
                // }
                // if (targetList && targetList.length > 0) {
                //   this.assessPlan.deleteTargetList = this.$_.concat(this.assessPlan.deleteTargetList, targetList);
                // }
                // this.assessPlan.stepCd = 'RAS000005'
                this.isComplete = !this.isComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completePlanCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: "RAS000005"
      })
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.riskAssessmentPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addTarget() {
      if (!this.assessPlan.plantCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공사현장을 먼저 입력바랍니다.', // 공사현장을 먼저 입력바랍니다.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.popupOptions.title = '단위공종 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.assessPlan.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closeProcessPopup;
    },
    closeProcessPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if(this.$_.findIndex(this.assessPlan.targetList, { processCd: _item.processCd }) === -1) {
            this.assessPlan.targetList.push({
              riskAssessmentTargetId: uid(),
              riskAssessmentPlanId: this.param.riskAssessmentPlanId,
              processCd: _item.processCd,
              processName: _item.processName,
              groupMdmSopId: _item.processCd + '/' + uid(),
              mdmSopId: '',
              sopName: '',
              saiContructFacilityId: '',
              facilityName: '',
              processAssessStepCd: '',
              constructVendorCd: this.vendorCd,
              workerCount: null,
              assessVendorId: this.vendorCd,
              assessVendorName: this.vendorName,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
              assessDate: this.$comm.getToday(),
              assessUserId: this.$store.getters.user.userId,
              assessUserName: this.$store.getters.user.userName,
            })
          }
        })
      }
    },
    removeTarget() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.assessPlan.deleteTargetList) { 
          this.assessPlan.deleteTargetList = [];
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.assessPlan.deleteTargetList, { riskAssessmentTargetId: item.riskAssessmentTargetId }) === -1
            && item.editFlag !== 'C') {
            this.assessPlan.deleteTargetList.push(item);
          }
          this.assessPlan.targetList = this.$_.reject(this.assessPlan.targetList, item);
          this.$refs['table'].$refs['compo-table'].clearSelection();
        });
      }
    },
    innerBtnClicked(col, props) {
      this.rowIndex = props.rowIndex;
      this.isRowAdd = true;
      if (col.name === 'processName') {
        this.addProcess(props)
      } else if (col.name === 'sopName') {
        this.addSop(props)
      }
    },
    addProcess(props) {
      let groupMdmSopId = props.row.processCd + '/' + uid();
      let process = { 
        riskAssessmentTargetId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
        groupMdmSopId: groupMdmSopId,
        processCd: props.row.processCd,
        processName: props.row.processName,
        mdmSopId: '',
        sopName: '',
        maps: this.$_.cloneDeep(props.row.maps),
        deleteMaps: [],
        saiContructFacilityId: '',
        facilityName: '',
        processAssessStepCd: '',
        constructVendorCd: this.vendorCd,
        workerCount: 0,
        assessVendorId: this.vendorCd,
        assessVendorName: this.vendorName,
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
        assessUserId: this.$store.getters.user.userId,
        assessUserName: this.$store.getters.user.userName,
      }
      this.$_.forEach(process.maps, _item => {
        _item.riskAssessMapId = uid(),
        _item.riskAssessmentTargetId = process.riskAssessmentTargetId
        _item.editFlag = 'C'
      })
      this.assessPlan.targetList.splice(this.rowIndex, 0, process)
    },
    addSop(props) {
      let sop = { 
        riskAssessmentTargetId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
        groupMdmSopId: props.row.groupMdmSopId,
        processCd: props.row.processCd,
        processName: props.row.processName,
        mdmSopId: props.row.mdmSopId,
        sopName: props.row.sopName,
        maps: this.$_.cloneDeep(props.row.maps),
        deleteMaps: [],
        saiContructFacilityId: '',
        facilityName: '',
        processAssessStepCd: '',
        constructVendorCd: this.vendorCd,
        workerCount: 0,
        assessVendorId: this.vendorCd,
        assessVendorName: this.vendorName,
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
        assessUserId: this.$store.getters.user.userId,
        assessUserName: this.$store.getters.user.userName,
      }
      this.$_.forEach(sop.maps, _item => {
        _item.riskAssessMapId = uid(),
        _item.riskAssessmentTargetId = sop.riskAssessmentTargetId
        _item.editFlag = 'C'
      })
      this.assessPlan.targetList.splice(this.rowIndex, 0, sop)
    },
    removeSop(row) {
      row.mdmSopId = null
      row.sopName = null
      if (row.editFlag !== 'C') {
        row.editFlag = 'U'
        row.chgUserId = this.$store.getters.user.userId
      }
    },
    srcClick(row) {
      this.row = row;

      this.popupOptions.title = '지도 위치'; // 지도 위치
      this.popupOptions.param = {
        riskAssessmentTargetId: row.riskAssessmentTargetId,
        maps: row.maps ? row.maps : [],
        isAll: false,
        deleteMaps: row.deleteMaps ? row.deleteMaps : [],
        sopMapId: this.assessPlan.sopMapId,
        stepCd: this.assessPlan.stepCd,
        disabled: Boolean(this.assessPlan.stepCd) && this.assessPlan.stepCd !== 'RAS000001',
      };
      this.popupOptions.target = () => import(`${'./riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    srcAllClick() {
      let maps = [];
      if (this.assessPlan.targetList && this.assessPlan.targetList.length > 0) {
        this.$_.forEach(this.assessPlan.targetList, _item => {
          this.$_.forEach(_item.maps, _map => {
            maps.push(_map)
          }) 
        })
      }
      this.popupOptions.title = '지도 및 전체위치 보기'; // 지도 위치
      this.popupOptions.param = {
        riskAssessmentTargetId: '',
        maps: maps,
        isAll: true,
        deleteMaps: [],
        sopMapId: this.assessPlan.sopMapId,
        stepCd: '',
        disabled: true,
      };
      this.popupOptions.target = () => import(`${'./riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.type === 'APPLY') {
        this.row.maps = data.maps;
        this.row.deleteMaps = data.deleteMaps;
        
        if (this.row.editFlag !== 'C') {
          this.row.editFlag = 'U'
          this.row.chgUserId = this.$store.getters.user.userId
        }
      }
    },
    datachange(row) {
      if (row.editFlag !== 'C') {
        row.editFlag = 'U'
        row.chgUserId = this.$store.getters.user.userId
      }
    },
    addDiffPlan() {
      this.popupOptions.title = '타 위험성평가 검색';
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'./riskAssessPlanPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '90%';
      this.popupOptions.closeCallback = this.closeDiffPlanPopup;
    },
    closeDiffPlanPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$http.url = this.$format(this.detailUrl, data[0].riskAssessmentPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.assessPlan = _result.data

          this.assessPlan.plantCd = this.$store.getters.user.plantCd;
          this.assessPlan.riskAssessmentPlanId = '';
          this.assessPlan.stepCd = 'RAS000001' // 평가계획
          this.assessPlan.regUserId = this.$store.getters.user.userId
          this.assessPlan.regUserName = this.$store.getters.user.userName
          this.assessPlan.regVendorName = this.$store.getters.user.deptName
          
          this.assessPlan.deleteTargetList = [];
          this.assessPlan.deleteVendorList = [];

          this.$_.forEach(this.assessPlan.targetList, _item => {
            _item.editFlag = 'C';
            _item.assessVendorId = '';
            _item.constructVendorCd = '';
            _item.vendorList = [];
          })
        },);
      }
    },
    reCheckRisk() {
      /**
       * 재검토
       * 
       *  - 지난 위험성평가에서 진행한 유해위험요인에 대해서 재검토 진행
       *  - 위험성평가 한 개 이상 선택
       *  - 대상 공종 포함 아래 정보 summary되어 추가
       */
      this.popupOptions.title = "위험성평가 재검토"; // 위험성평가 재검토
      this.popupOptions.param = {
        riskAssessmentPlanId: this.assessPlan.riskAssessmentPlanId,
        sopMapId: this.assessPlan.sopMapId,
      };
      this.popupOptions.target = () => import(`${"./riskAssessReCheckPop.vue"}`);
      this.popupOptions.width = "90%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRecheckPopup;
    },
    closeRecheckPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let filteredArr = data.reduce((acc, current) => {
          const x = acc.find(item => item.riskAssessmentPlanId === current.riskAssessmentPlanId && item.assessVendorId === current.assessVendorId);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        // let _recheckData = [];
        // this.$_.forEach(summaryData, _item1 => {
        //   this.$_.forEach(filteredArr, _item2 => {
        //     if (_item1.riskAssessmentPlanId === _item2.riskAssessmentPlanId
        //     && _item1.assessVendorId === _item2.assessVendorId) {
        //       console.log(_item1)
        //       _recheckData.push(_item1);
        //     }
        //   })
        // })

        let _riskAssessmentItems = [];
        this.$_.forEach(data, item => {
          _riskAssessmentItems.push(
            {
              riskAssessmentPlanId: item.riskAssessmentPlanId,
              assessVendorId: item.assessVendorId,
              processCd: item.processCd,
              sopName: item.sopName,
              riskHazardClassCd: item.riskHazardClassCd,
            }
          )
        })

        this.$http.url = this.recheckSaveUrl;
        this.$http.type = 'POST';
        this.$http.param = {
          riskAssessmentPlanId: this.param.riskAssessmentPlanId,
          stepCd: this.param.stepCd,
          sopMapId: this.assessPlan.sopMapId,
          regUserId: this.$store.getters.user.userId,
          recheckData: data,
          riskAssessmentPlanIds: this.$_.map(filteredArr, 'riskAssessmentPlanId'),
          riskAssessmentItems: _riskAssessmentItems,
        };
        // console.log('_recheckData',_recheckData)
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetail();
        },);
      }
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getDetail();
    },
  }
};
</script>

<style lang="sass">
.customSearch
  padding-bottom: 0px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customSearch:hover
  .dateCloseIcon
    display: block
.customSearch.q-field--dense .q-field__control, .customSearch.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customSearch.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style>